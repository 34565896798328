<template>
    <router-view
        v-if="isAllowedTo('SupplierCentral/getAllSupplierIntegrationConfig', 'SupplierCentral/getSupplierIntegrationAttributes', 'SupplierCentral/getSupplierIntegrationProducts')"
        ref="routeview"
        :supplier-integration-config="supplierIntegrationConfig"
        :supplier-name="supplierName"
        :supplier-integration-supplier-id="supplierIntegrationSupplierId"
    ></router-view>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'SupplierIntegrationDetail',
    data () {
        return {
            breadcrumbElement: {
                name: '...',
            },
            supplierName: null,
            supplierIntegrationSupplierId: null,
            supplierIntegrationConfig: new Promise((resolve, reject) => {
                const jobName = JSON.stringify(this.localConfiguration)

                this.addJob(jobName)
                const url = CONFIG.API.ROUTES.SUPPLIER_INTEGRATIONS.GET.replace('{supplierIntegrationConfigId}', this.$route.params.supplierIntegrationConfigId)

                this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    resolve(data.data)
                }).catch(data => {
                    reject(data)
                    this.$root.$emit('notification:global', {
                        message: `Getting supplier integration configuration failed. Please try again.`,
                        type: 'error',
                        errors: data,
                    })
                }).finally(() => {
                    this.finishJob(jobName)
                })
            }),
        }
    },
    created () {
        this.supplierIntegrationConfig.then(data => {
            this.breadcrumbElement.name = data.supplierName
            this.supplierName = data.supplierName
            this.supplierIntegrationSupplierId = data.supplierIntegrationSupplierId
        })
    },
}
</script>
